.form-tabs {
    position: relative;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding-right: 60px;
    margin-top: 1rem;

    > .nav-item {
        position: relative;
        display: flex;
        width: auto;

        &.add-button {
            background-color: white;
        }

        > .nav-link {
            white-space: nowrap;

            .remove-btn {
                flex: 0 0 32px;
                padding-left: 6px;
                padding-right: 6px;
                font-weight: bold;
                font-size: 0.7rem;
                margin-left: 15px;
                margin-top: -3px;
                padding-top: 3px;
                padding-bottom: 3px;
            }
        }
    }
}
.form-tab-content {
    border: 2px solid $primary;
    padding: 15px 32px 15px 15px;
    border-radius: 5px;
}
