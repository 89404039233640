@mixin fieldobject($fieldname) {
    .form-group.field.field-object.#{$fieldname} {
        > fieldset, > .form-group > .prop-fields {
            @content;
        }
    }

    .form-group.field.field-array.#{$fieldname} {
        > fieldset > div > .array-cards {
            > .array-item-list {
                @content;
            }
        }
    }

    .array-item.for-object-row.#{$fieldname} {
            @content;
    }
}


@mixin fieldobjectGrid($fieldname, $columns) {
    $column-end: $columns + 1;
    @include fieldobject($fieldname) {
        display: grid;
        grid-template-columns: repeat($columns, 1fr);
        grid-template-rows: repeat(2, auto);
        grid-column-gap: 20px;
        grid-row-gap: 0px;
    
        .file-input > .input-group-text {
            width: 10em;
        }
    
    
        .field-description{
            grid-column: 1/$column-end;
        }

        .to-col-end {
            grid-column-end: $column-end !important;
        }

        @media only screen and (max-width: 600px) {
            grid-template-columns: repeat(1, 1fr);
        }

    }
}