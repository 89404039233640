.file-input {
    > label.file-input-label {
        margin: 0;
        > input[type=file] {
            opacity: 0;
            width: 1px;
        }
    }
    
    > .input-group-text {
        max-width: 15em;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        text-align: center;
        background-color: #fff;
    }
    
    > :not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    
    > :not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .fa {
        font-size: 10pt;
    }
}
