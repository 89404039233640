.log-entry {
    margin-top: 2px;
}

table.log-table {
    width: 100%;
    > thead > tr > th { text-align: center; }
    > tbody > tr {
        cursor: default;
        border: 1px solid gray;
        > td {
            padding-left: 1em;
            padding-right: 1em;
            > .idx { text-align: center; }
        }
        &:not(.selected):not(.selected-expanded):hover {
            background-color: $primary-card-bg;
        }
        &.selected {
            background-color: $primary-card-bg;
            border-bottom: 0;

            >td {
                font-weight: bold;
            }
        }
        &.selected-expanded {
            cursor: inherit;
            background-color: rgba($primary-card-bg, .3);
            border-top: 0;

            &:not(.last) {
                border-bottom: 0;
            }

            &.last {
                padding-bottom: 1em;
            }
        }
    }
}

.log-entry:last-child {
    margin-top: 32px;
}

.log-entry .card-header {
    position: relative;
}

.log-entry .card-header>.action {
    position: absolute;
    right: 15px;
    font-size: .7em;
}

.log-entry .card-header>.index {
    display: inline-block;
    min-width: 2em;
    text-align: center;
    padding-right: 1em;
}

.log-entry .card-header>.status {
    display: inline-block;
}

.log-entry .card-header>.status {
    display: inline-block;
}


.log-entry .state, table.log-table .state {
    font-family: monospace;
    font-size: .8em;
    background-color: rgba(128, 128, 128, .1);
    white-space: pre;
}

.log-entry .timestamp {
    font-size: .9em;
}

.uweentity-history-list {
    margin: -20px;
    > .entry {
        padding: 5px 20px;

        &:not(:last-child) {
            border-bottom: 1px solid $striped-list-border-color;
        }

        &:nth-of-type(odd) {
            background-color: $striped-list-background-color;
        }

        &:last-child {
            border-bottom-left-radius: 15px;
        }

        .timestamp {
            font-size: .7rem;
            font-weight: bold;
        }
        .username {
            font-size: .7rem;
            color: $dark-gray;
        }
        .action {
            font-size: .85rem;
            color: $black;
        }
        .option {
            font-size: .75rem;
            color: $black;
        }
        .reason {
            border: 1px solid $dark-gray;
            font-size: .85rem;
            color: $black;
            padding: 3px 5px;
        }
    }
}

.uweentity-history-expanded {
    .uweentity-history {
        .date-time {
            font-size: small;
            font-weight: bold;
        }

        .text {
            white-space: pre-wrap;
        }
    }
}