

@for $i from 1 through 6 {
    .from-col-#{$i} {
        grid-column-start: $i !important;
    }

    .to-col-#{$i + 1} {
        grid-column-end: ($i + 1) !important;
    }

    .from-row-#{$i} {
        grid-row-start: $i !important;
    }

    .to-row-#{$i + 1} {
        grid-row-end: ($i + 1) !important;
    }

    @for $j from 1 through 6 {
        .from-col-#{$i}-size-#{$j} {
            grid-column-start: $i !important;
            grid-column-end: ($i + $j) !important;
        }

        .from-row-#{$i}-size-#{$j} {
            grid-row-start: $i !important;
            grid-row-end: ($i + $j) !important;
        }
    }
}


.pad-right {
    padding-right: 1rem;
}


@include fieldobject(items-centered) {
    justify-items: center;
}