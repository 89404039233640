@font-face {
    font-family: 'dimo-icons';
    src:  url('fonts/dimo-icons.eot?pq62lt');
    src:  url('fonts/dimo-icons.eot?pq62lt#iefix') format('embedded-opentype'),
          url('fonts/dimo-icons.ttf?pq62lt') format('truetype'),
          url('fonts/dimo-icons.woff?pq62lt') format('woff'),
          url('fonts/dimo-icons.svg?pq62lt#dimo-icons') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
  
.dimo-icon, .dimo-icon > i {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-size: inherit;
    font-family: 'dimo-icons' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$icons: (
    expedientes: (
        1: (content: "\e900",),
        2: (content: "\e901", margin-left: -1em),
        3: (content: "\e902", margin-left: -1em),
        4: (content: "\e903", margin-left: -1em),
    ),
    registro: ( 
        1: (content: "\e904",),
        2: (content: "\e905", margin-left: -1em, opacity: .5),
        3: (content: "\e906", margin-left: -1em, color: rgb(255, 255, 255)),
    ),
    v_mant: (
        1: (content: "\e907",),
    ),
    v_uso: (
        1: (content: "\e908",),
    ),
    archivo: (
        1: (content: "\e909",),
    ),
    transferencia: (
        1: (content: "\e90a",),
    ),
    tarjeta: (
        1: (content: "\e90b",),
    ),
    bajas: (
        1: (content: "\e90c",),
    ),
    marbete: ( 
        1: (content: "\e90d",),
        2: (content: "\e90e", margin-left: -1em, color: rgb(255, 255, 255)),
    ),
    licencias: ( 
        1: (content: "\e90f",),
        2: (content: "\e910", margin-left: -1em, color: rgb(255, 255, 255)),
    ),
    accidentes: (
        1: (content: "\e911",),
    ),
    home: (
        1: (content: "\e912",),
    )
);

@mixin iconpath($selector, $path) {
    #{$selector}:before {
        content: map-get($path, "content");
        margin-left: map-get($path, "margin-left");
        color: map-get($path, "color");
    }
}

@each $name, $paths in $icons {
    .dimo-icon-#{$name} {
        @if length($paths) == 1 {
            $path: map-get($paths, 1);
            @include iconpath("&", $path);
        } @else {
            @each $idx, $path in $paths {
                @include iconpath(".p#{$idx}", $path);
            }
        }
    }
}