.uweentity-form.hidden {
    display: none;
}

form.rjsf {
    margin-top: -1.25rem;

    .alert {
        input {
            background-color: transparent;
            color: inherit;
            border-color: transparent;

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }

    .is-highligthed {
        outline: 5px solid blue;
        cursor: crosshair !important;
    }

    .form-group {
        .control-label {
            margin-bottom: .5rem;
        }

        .checkbox-inline>span {
            margin-right: $inline-input-gap;

            >input[type=checkbox] {
                margin-right: $inline-label-gap;
            }
        }

        .checkbox>label>span {
            margin-right: $inline-input-gap;

            >input[type=checkbox] {
                margin-right: $inline-label-gap;
            }
        }

        .field-radio-group>.radio {
            margin-right: $inline-input-gap;

            >label>span>input[type=radio] {
                margin-right: $inline-label-gap;
            }
        }

        .field-radio-group.radio-group-buttons {
            .btn + .btn {
                margin-left: 12px;
            }
        }

        .radio-inline {
            margin-right: $inline-input-gap;

            >span {
                >input[type=radio] {
                    margin-right: $inline-label-gap;
                }
            }
        }

        @media (min-width: 768px) {

            input,
            select {
                max-width: 20rem;
            }
        }

        .btn.btn-block {
            margin-left: 3rem;
            margin-right: 3rem;
            width: 90%;
        }
    }

    .title-2-lines>label {
        height: 3em;
    }

    .array-field-vertical .array-item-list {
        flex-direction: column;
        max-width: 30rem;

        >.array-item {
            display: flex;

            >.col-xs-9 {
                flex-grow: 1;

                .form-group {
                    margin-bottom: 0;
                }
            }

            >.col-xs-3 {
                display: flex;
                align-items: center;
            }
        }
    }

    .array-cards>.array-item-list>.array-item.for-object-row {
        padding-left: 1em;
        padding-right: 1em;
    }

    tr.for-object-row>th {
        white-space: pre;
    }

    .array-item.for-object-row>td {
        padding: 0;

        >.form-group {
            margin-bottom: 0;

            >.control-label {
                display: none;
            }

            >.form-group {
                margin-bottom: 0;
            }
        }
    }

    tr.array-item.panel-row>td {
        padding: 0;

        >.form-group {
            margin-bottom: 0;

            &.bordered>.form-group>.prop-fields {
                padding-left: 12px;
                padding-right: 12px;
            }
        }
    }

    .form-group:last-of-type {
        margin-bottom: 0;
    }

    .form-group.bordered {
        border: 1px solid $gray;
    }

    legend[id^="root_"][id$="__title"] {
        position: relative;
        cursor: default;
        background-color: $pd-color-4;
        color: $primary-text;
        border-radius: $border-radius $border-radius 0 0;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        margin-left: -1.25rem;
        margin-right: -1.25rem;
        box-sizing: content-box;

        @media (max-width: 768px) {
            margin-left: -5px;
            margin-right: 9px;
            padding-left: 14px;
            padding-right: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        &.type-subsection {
            background-color: $secondary;
            color: inherit;
            width: auto;
            padding-left: 1.25rem;
            padding-right: 1.25rem;
            margin-left: 0rem;
            margin-right: 0rem;
            font-size: 1.25rem;
            font-weight: normal;

            >.expandable {
                top: -2px;
                right: 5px;
                ;
                color: inherit;

                >* {
                    font-size: 1.75rem;
                }
            }
        }


        &.type-subsubsection {
            background-color: $secondary;
            color: inherit;
            width: auto;
            padding-left: 1.25rem;
            padding-right: 1.25rem;
            margin-left: 0rem;
            margin-right: 0rem;
            font-size: 1rem;
            font-weight: normal;

            >.expandable {
                top: -2px;
                right: 5px;
                color: inherit;

                >* {
                    font-size: 1.5rem;
                }
            }
        }

        &.type-label {
            color: inherit;
            background-color: transparent;
        }

        >.expandable {
            position: absolute;
            right: 12px;
            height: 1.5em;
            width: 1.5em;
            background-color: transparent;
            border-width: 0;
            color: $white;

            >* {
                font-size: 2.5rem;
            }
        }
    }


    legend.type-label {
        font-size: large;
    }


    .btn.btn-add {
        $color: map-get($theme-colors, "secondary");
        @include button-variant($color, $color);
    }

    .radio-column {
        >.field-radio-group {
            flex-direction: column;
        }
    }

    legend#root__title:empty {
        margin: 0;
    }
}

.field-boolean {
    position: relative;

    >.checkbox {
        display: inline-block;
    }

    >.help-block {
        display: inline;
        margin-bottom: 0;
        vertical-align: middle;
    }
}

.help-block {
    display: inline;
    margin-bottom: 0;
    margin-left: 3px;
    vertical-align: middle;
}

.form-group.field.field-object.flex-row>fieldset {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    >* {
        margin-left: 12px;
    }
}


.no-title>fieldset>legend {
    display: none;
}


.row-index {
    width: 4em;

    >.form-group>input {
        text-align: center;
    }
}

.form-container {
    display: flex;
    flex-direction: row;
    margin-left: -24px;
    margin-right: -24px;

    >.sidepanel {
        display: flex;
        flex-direction: column;
        $tabwidth: 120px;
        $tabheight: 24px;
        $tabgap: 10px;
        $tab-v-offset: 36px+2 * 8px;
        $tab-border-radius: $border-radius;
        $collapsed-width : $tabheight + $tabgap;
        $expanded-width : 260px;

        &.expanded {
            flex: 0 0 $expanded-width;
        }

        flex: 0 0 $collapsed-width;
        width: $collapsed-width;

        >.expandable-tab-side-panel-tab {
            width: $tabwidth;
            height: $tabheight;
            border-radius: $tab-border-radius $tab-border-radius 0px 0px;
            font-size: 1rem;
            margin-bottom: $tabwidth;
            border-width: 0;
            color: $white;
            background-color: $pd-color-4;
        }

        >.expandable-tab-side-panel-panel {
            margin-left: $tabgap / 2;
            margin-right: $tabgap / 2;
            border-radius: $border-radius;

            >.card-header {
                position: relative;
                background-color: $pd-color-4;
                color: $white;
                font-size: 1.65rem;
                padding-top: 0;
                font-weight: bold;
                padding-bottom: 0;

                >.btn-bar {
                    position: absolute;
                    right: 0;
                    top: -8px;
                    padding: 0;

                    >.btn {
                        position: relative;
                        right: auto;
                        top: auto;
                        border-radius: 50%;
                        background-color: $pd-color-4;
                        color: $white;
                        width: 24px;
                        min-width: auto;
                        max-width: auto;
                        padding: 0;
                        height: 24px;
                        margin-left: 12px;

                        &:hover {
                            color: $pd-color-4;
                            background-color: $white;
                        }
                    }
                }
            }

            >.card-body {
                overflow: auto;
                max-height: 40vh;
                border-radius: 0;
            }

            &::after {
                content: " ";
                height: 15px;
            }
        }

        >.expandable-tab-side-panel-tab+.expandable-tab-side-panel-panel {
            margin-top: $tab-v-offset;
        }

        >.expandable-tab-side-panel-panel+.expandable-tab-side-panel-panel {
            margin-top: 2 * $tabgap;
        }

        &.sidepanel-left {
            >.expandable-tab-side-panel-tab {
                // rotate(-90deg) translateY(9px) translateX(-173px)
                transform: rotate(-90deg) translate(-($tabwidth + $tab-v-offset), $tabgap);
                transform-origin: top left;
            }

            &.expanded>.expandable-tab-side-panel-tab {
                transform: rotate(-90deg) translate(-($tabwidth + $tab-v-offset), $expanded-width - $tabheight);
            }

            order: 1;
        }

        &.sidepanel-right {
            >.expandable-tab-side-panel-tab {
                transform: rotate(90deg) translate($tabwidth + $tab-v-offset, $tabwidth - $tabheight);
                transform-origin: top right;
            }

            &.expanded>.expandable-tab-side-panel-tab {
                transform: rotate(90deg) translate($tabwidth + $tab-v-offset - $tabheight, $tabwidth);
                transform-origin: bottom right;
            }

            order: 3;
        }

    }


    >.form-component {
        order: 2;
        flex-grow: 1;
    }

    >.toolbar-bottom-affix {
        position: fixed;
        bottom: 0;
        margin-left: 5px;
    }

}


.form-group.field.field-inline {
    display: flex;
    flex-direction: row;

    >label {
        margin-bottom: 0;
        padding-right: 12px;

        &:after {
            content: ": "
        }
    }

    >.form-group {
        margin-bottom: 0;
        flex-grow: 1;

        >input {
            width: 100%;
            max-width: 1200px;
        }
    }
}


.has-buttons-position-top {
    position: relative;

    .buttons-position-top {
        top: 3px;
        position: absolute;
        right: 40px;
    }
}

.has-buttons-position-top-outside {
    position: relative;
    display: flex;
    margin-left: 5em;
    justify-content: space-between;

    .buttons-position-top {
        flex-grow: 0;
        flex-shrink: 0;
    }

}

$cell-widths: (
    l: 16rem,
    m: 10rem,
    sm: 4.5rem
);

th, td {
    @each $k, $v in $cell-widths {
        &.w-#{$k} {
            width: $v;
            max-width: $v;
        }
    }
}


.for-object-row {
    >th {
        text-align: center;
        vertical-align: middle;
    }


    .w-sm-s {
        width: 4.5rem;
        font-size: 13px;
        padding: 2px;
    }

    ::-webkit-input-placeholder {
        color: #b4b4b4;
        font-size: 14px;
    }

    .w-sm-s :-ms-input-placeholder {
        color: #b4b4b4;
        font-size: 14px;
    }

    .w-sm-s ::placeholder {
        color: #b4b4b4;
        font-size: 14px;
    }
}

.div-login {
    margin-top: 3.5rem;
}

.quill{
    height: 300px;
}

.property-table {
    width: 100%;
}