.uweentity-note-sidepanel-component {
    .uweentity-note-add {
        textarea {
            width: 100%;
            height: 100px;
            border-radius: 5px;
        }
    }

    .uweentity-notes-list {
        &::before {
            content: " ";
            border-top: 1px solid $separator-color;
            display: block;
            margin: .5rem -20px;
            height: 0;
        }
    }

    .uweentity-notes-list .uweentity-note-container {
        &:not(:last-child)::after {
            content: " ";
            border-top: 1px solid $separator-color;
            display: block;
            margin: .5rem -20px;
            height: 0;
        }

        .uweentity-note-header {
            font-size: small;
        }

        .uweentity-note-date-time {
            font-size: small;
            font-weight: bold;

            &::after {
                content: " - ";
            }
        }

        .uweentity-note-user {
            font-size: small;
            font-weight: bold;

            &::after {
                content: " - ";
            }
        }

        .uweentity-note-action {
            float: right;
            font-size: small;
            font-weight: bold;
        }

        .uweentity-note-text {
            white-space: pre-wrap;
        }

    }
}


.uweentity-notes-expanded {

    .uweentity-form-notes{
        padding-bottom: 20px;
    }

    .uweentity-note-date-time {
        font-size: small;
        font-weight: bold;
    }

    .uweentity-note-user {
        font-size: small;
        font-weight: bold;
    }

    .uweentity-note-action {
        float: right;
        font-size: small;
        font-weight: bold;
    }

    .uweentity-note-text {
        white-space: pre-wrap;
    }

}