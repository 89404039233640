.button-bar {
    display: flex;
    flex-direction: row;

    .button-group {
        display: flex;
        flex-direction: row;

        + .button-group {
            margin-left: 1em;
        }
    }
}