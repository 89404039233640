.nav.nav-tabs {
    $color: $primary;
    $text-color: $primary-text;

    .nav-item {
        &.side-right {
            margin-left: auto;
            & ~ .side-right {
                margin-left: unset;
            }
        }
    }

    .nav-link {
        &:not(.active) {
            border-color: $border-color;
            color: $color;
        }
        &:hover {
            background: transparentize($color, .95);
        }
        &.active {
            border-color: $color;
            color: $primary-text;
            background-color: $color;
        }
    }
}