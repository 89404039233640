.table-wrapper {
    &.table-fixed-header {
        overflow-y: auto;

        > .table {
            border-collapse: separate
        }
    }

    &.table-fixed-columns {
        overflow-x: auto;

        > .table {
            border-collapse: separate
        }
    }
}
div:has(> table.table){
    overflow-x: auto;
}