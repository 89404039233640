.json-schema-tree-editor {
    position: relative;
    background-color: white;
    border: 1px solid black;

    .json-schema-tree-node-object, .json-schema-tree-node-array {
        position: relative;
        grid-column-start: 1;
        grid-column-end: 5;
        display: grid;
        grid-template-columns: $grid-columns;
        border-left: 1px solid black;
        padding-left: 12px;
    }

    label.switch {
        margin-bottom: 0;
        justify-self: center;
    }

    .json-schema-tree-node {
        --indent: 16px;

        &.hide-root.root{
            --indent: 0;
        }

        > input[type=text] {
            width: auto;
        }

        > .jstn-header {
            display: flex;

            > .input-group {
                position: relative;
                width: auto;

                > input.has-buttons {
                    padding-right: 26px;
                }
            }

            > * {
                height: 28px;
            }
        }

        > .jstn-attributes {
            position: relative;
            margin-left: var(--indent);
            border-left: 1px solid black;
            > .title {
                padding-left: 1em;
            }
            > .json-tree-editor {
                border: 0;
                > .json-tree-editor-node-object {
                    border: 0;
                }
            }
        }

        > .jstn-children {
            margin-left: var(--indent);
        }

        .btn {
            &.add-prop-btn {
                font-size: 0.8rem;
                line-height: 1em;
                padding: 5px 20px;
            }
            &.delete-btn {
                position: absolute;
                top: 4px;
                right: 2px;
                font-size: 0.8rem;
                padding: 0 5px;
                margin-left: 0;
                line-height: 1em;
            }
            &.attribs-btn {
                font-size: 0.8rem;
                padding: 0 5px;
                margin-left: 0;
                line-height: 1em;
            }
        }
    }

    .type-selector {
        > select {
            width: 100%;
            height: 100%;
        }
    }

    .index {
        grid-column-start: 1 !important;
        > input { width: 100%; }
        > .number {
            text-align: right;
            padding-right: 2em;
            &::before {
                content: "#";
            }
        }
    }

    .lower-buttons {
        grid-column-start: 1 !important;
        text-align: right;
        .btn {
            font-size: .8rem;
            line-height: 1em;
            padding: 5px 20px;
        }
    }

    .buttons {
        display: flex;
        flex-direction: row;
        &:not(.inplace) {
            grid-column-start: 4 !important;
        }
        align-items: flex-start;
        .btn {
            > .fa { font-size: inherit; }
            font-size: .6rem;
            line-height: 1em;
            width: 2.5em;
            padding: 5px 5px;
        }
    }

    .item-count {
        padding-left: 5px;
    }

    input {
        border: 1px solid black;
    }

    .number-editor {
        position: relative;
        > input {
            padding-left: 12px;
            text-align: right;
            width: 100%;
            padding-right: 12px;
        }
        > .marker {
            position: absolute;
            top: 5px;
            left: 5px;
            color: red;
        }
    }
}

* .json-schema-type-select.dropdown {
    line-height: 1em;
    padding: 0;
    border-width: 0;

    > .dropdown-toggle {
        line-height: 1em;
        width: 6em;
        padding: 4px 0;
        position: relative;
        background-color: transparent;
        border-radius: 0;
        border-color: black;
        > i {
            position: relative;
            left: -.1em;
        }
        &::after {
            position: absolute;
            top: .8em;
            right: .1em;
            font-size: .83em;
        }
        &:focus{
            outline: none;
        }
    }

    > .dropdown-menu {
        min-width: auto;
        > .dropdown-item {
            padding: .25rem;

            &.active {
                background-color: $selected-item-color;
            }
        }
    }
}
