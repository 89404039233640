.clock {
    display: inline-block;
    height: 1em;
    line-height: 1em;
    > .time, > .date {
        font-size: inherit;
    }
    > .ampm {
        display: inline-block;
        padding-left: .25em;
        font-size: .5em;
        line-height: 1em;
        &.is-am {
            padding-bottom: 1em;
            vertical-align: text-bottom;
        }
    }
}